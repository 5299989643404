import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BigHeader from "../../components/bigHeader"
import { StaticQuery, graphql } from "gatsby"

const FamilyBusiness = ({ contentField }) => {
  const lang = "it"
  const seo = contentField.seo
  const image = contentField.featuredImage

  return (
    <Layout
      lang={lang}
      translationEN="/our-company/"
      translationDE="/de/uber-hiddenfjord/"
      translationES="/es/una-emepresa-familiar/"
      translationFR="/fr/notre-compagnie/"
    >
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <BigHeader
        title="LA NOSTRA AZIENDA"
        image={require("../../assets/images/family-business-back.jpg")}
      />

      <div className="main-section">
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.content,
          }}
        ></div>
        <div className="text-container">
          <div className="quote-container">
            <i className="quote-container__text">
              {contentField.OurCompanyACF.quote}
            </i>
            <p className="quote-container__author">
              {contentField.OurCompanyACF.author}
            </p>
          </div>
          <div className="img-right">
            <div className="row">
              <div
                className="col-md-7"
                dangerouslySetInnerHTML={{
                  __html: contentField.OurCompanyACF.textLeft,
                }}
              />
              <div className="col-md-5">
                <img
                  src={require("../../assets/images/diploma.jpg")}
                  alt="diploma"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDoxNjQy" }) {
          content
          OurCompanyACF {
            author
            quote
            textLeft
          }
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <FamilyBusiness {...data} />}
  />
)
